import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Input,
  Select,
  FormControl,
  FormLabel,
  Text,
  VStack,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import wordsJson from "../index/words.json";

function AdminArticle() {
  const [password, setPassword] = useState("");
  const [org, setOrg] = useState("");
  const [type, setType] = useState("");
  const [topic, setTopic] = useState("");
  const [englishTitle, setEnglishTitle] = useState("");
  const [chineseTitle, setChineseTitle] = useState("");

  const [englishFile, setEnglishFile] = useState(null);
  const [chineseFile, setChineseFile] = useState(null);
  const [eventTopics, setEventTopics] = useState([]);
  const [blogTopics, setBlogTopics] = useState([]);

  // State for password dialog
  const [isPasswordDialogOpen, setIsPasswordDialogOpen] = useState(false);
  const passwordCancelRef = useRef();
  const toast = useToast();

  useEffect(() => {
    if (org !== "") {
      setEventTopics(wordsJson[org]["English"].pastEvent.filter);
      setBlogTopics(wordsJson[org]["English"].blogSection.filter);
    } else {
      setEventTopics([]);
      setBlogTopics([]);
    }
  }, [org]);

  const handleFileChange = (e, setFile) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async () => {
    // Validation
    if (
      !org ||
      !type ||
      !topic ||
      !englishTitle ||
      !chineseTitle ||
      !englishFile ||
      !chineseFile
    ) {
      toast({
        title: "Error",
        description: "All fields must be filled.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    // Open password dialog
    setIsPasswordDialogOpen(true);
  };

  const handlePasswordSubmit = async () => {
    if (!password) {
      toast({
        title: "Error",
        description: "Password is required.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const formData = new FormData();

    // Append form data fields
    formData.append("org", org);
    formData.append("type", type);
    formData.append("category", topic);
    formData.append("titleCN", chineseTitle);
    formData.append("titleEN", englishTitle);
    formData.append("password", password);

    // Append file inputs
    formData.append("englishFile", englishFile);
    formData.append("chineseFile", chineseFile);

    try {
      const response = await axios.post(
        "https://australianwallstreet.com/api/upload-file",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data);
      toast({
        title: "Success",
        description: "Your data has been submitted successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: "Error",
        description: "Incorrect Password / Error",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.error("File upload failed:", err);
    }
    setIsPasswordDialogOpen(false); // Close password dialog
  };

  return (
    <Box p={5}>
      <VStack spacing={4} align="stretch">
        <Text fontSize="2xl">Welcome to AWS Backend Admin Access</Text>

        {/* Org Selection */}
        <FormControl>
          <FormLabel>Organization</FormLabel>
          <Select
            placeholder="Select Org"
            value={org}
            onChange={(e) => setOrg(e.target.value)}
          >
            <option value="Pro">Pro</option>
            <option value="UNSW">UNSW</option>
            <option value="UMelb">UMelb</option>
          </Select>
        </FormControl>

        {/* Type Selection */}
        <FormControl>
          <FormLabel>Type</FormLabel>
          <Select
            placeholder="Select Type"
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            <option value="blog">Blog</option>
            <option value="event">Events</option>
          </Select>
        </FormControl>

        {/* Topic Selection */}
        {type && (
          <FormControl>
            <FormLabel>Topic</FormLabel>
            <Select
              placeholder="Select Topic"
              value={topic}
              onChange={(e) => setTopic(e.target.value)}
            >
              {(type === "blog" ? blogTopics : eventTopics).map((topic) => (
                <option key={topic.value} value={topic.value}>
                  {topic.display}
                </option>
              ))}
            </Select>
          </FormControl>
        )}

        {/* English Title */}
        <FormControl>
          <FormLabel>English Title</FormLabel>
          <Input
            placeholder="Enter English Title"
            value={englishTitle}
            onChange={(e) => setEnglishTitle(e.target.value)}
          />
        </FormControl>

        {/* Chinese Title */}
        <FormControl>
          <FormLabel>Chinese Title</FormLabel>
          <Input
            placeholder="Enter Chinese Title"
            value={chineseTitle}
            onChange={(e) => setChineseTitle(e.target.value)}
          />
        </FormControl>

        {/* English File Upload */}
        <FormControl>
          <FormLabel>Upload English File</FormLabel>
          <Input
            type="file"
            onChange={(e) => handleFileChange(e, setEnglishFile)}
          />
        </FormControl>

        {/* Chinese File Upload */}
        <FormControl>
          <FormLabel>Upload Chinese File</FormLabel>
          <Input
            type="file"
            onChange={(e) => handleFileChange(e, setChineseFile)}
          />
        </FormControl>

        {/* Submit Button */}
        <Button onClick={handleSubmit} colorScheme="blue" mt={4}>
          Submit
        </Button>

        {/* Password Dialog */}
        <AlertDialog
          isOpen={isPasswordDialogOpen}
          leastDestructiveRef={passwordCancelRef}
          onClose={() => setIsPasswordDialogOpen(false)}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Enter Password
              </AlertDialogHeader>

              <AlertDialogBody>
                <FormControl>
                  <FormLabel>Password</FormLabel>
                  <Input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    padding={1}
                  />
                </FormControl>
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button
                  ref={passwordCancelRef}
                  onClick={() => setIsPasswordDialogOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  colorScheme="blue"
                  onClick={handlePasswordSubmit}
                  ml={4}
                >
                  Submit
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </VStack>
    </Box>
  );
}

export default AdminArticle;
