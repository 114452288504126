import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../index/ThemeContext";
import axios from "axios";
import memberWords from "./words.json";

const wukongStyles = `
.dashboard-container {
  background:rgb(255, 255, 255);
  min-height: 100vh;
  color: #e4d5b7;
  font-family: 'Noto Serif SC', serif;
}

.welcome-section {
  background: linear-gradient(to right, #1a1a1a 0%, #2d2d2d 100%);
  border: 1px solid #3d3d3d;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.5);
  position: relative;
  overflow: hidden;
}

.welcome-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent 25%,
    rgba(228, 213, 183, 0.1) 50%,
    transparent 75%
  );
  animation: shine 3s infinite;
}

@keyframes shine {
  100% { left: 200%; }
}

.avatar {
  background: #2d2d2d;
  border: 2px solid #e4d5b7;
  box-shadow: 0 0 10px rgba(228, 213, 183, 0.3);
}

.event-card {
  background: #1a1a1a;
  border: 1px solid #3d3d3d;
  border-radius: 8px;
  transition: transform 0.3s ease;
  position: relative;
  overflow: hidden;
}

.event-card:hover {
  transform: translateY(-5px);
}

.event-card::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, transparent 50%, rgba(228, 213, 183, 0.05));
  pointer-events: none;
}

.status-badge {
  background: #5a3921;
  color: #e4d5b7;
  text-shadow: 0 0 5px rgba(228, 213, 183, 0.3);
  border: 1px solid #7a5c3c;
}

.button-primary {
  background: linear-gradient(to bottom, #5a3921 0%, #3d2817 100%);
  border: 1px solid #7a5c3c;
  color: #e4d5b7;
  transition: all 0.3s ease;
}

.button-primary:hover {
  background: linear-gradient(to bottom, #7a5c3c 0%, #5a3921 100%);
  box-shadow: 0 0 15px rgba(122, 92, 60, 0.5);
}

.button-danger {
  background: linear-gradient(to bottom, #6d1a1a 0%, #4a1010 100%);
  border: 1px solid #8d4a4a;
  color: #ffb3b3;
  transition: all 0.3s ease;
}

.button-danger:hover {
  background: linear-gradient(to bottom, #8d4a4a 0%, #6d1a1a 100%);
  box-shadow: 0 0 15px rgba(141, 74, 74, 0.5);
}

.button-primary:disabled,
.button-danger:disabled {
  background: linear-gradient(to bottom, #3d2817 0%, #2d1a0f 100%);
  opacity: 0.7;
  cursor: not-allowed;
  box-shadow: none;
}

.button-primary:disabled:hover,
.button-danger:disabled:hover {
  background: linear-gradient(to bottom, #3d2817 0%, #2d1a0f 100%);
}

.disabled-tooltip {
  color: #ff6666;
  font-size: 0.8rem;
  margin-top: 0.3rem;
  display: block;
}

.stat-number {
  color: #e4d5b7;
  text-shadow: 0 0 5px rgba(228, 213, 183, 0.3);
}

.textarea-custom {
  background: #2d2d2d;
  border: 1px solid #3d3d3d;
  color: #e4d5b7;
  font-size: 1.1rem;
  line-height: 1.5;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .event-grid {
    grid-template-columns: 1fr;
  }
}
`;

function MemberDashboard() {
  const navigate = useNavigate();
  const [memberInfo, setMemberInfo] = useState({});
  const [currentMemberId] = useState(() => {
    const storedId = localStorage.getItem("currentMemberId");
    return storedId || "";
  });
  const { language } = useLanguage();
  const words = memberWords[language].dashboard;
  const [events, setEvents] = useState([]);
  const [joinedEvents, setJoinedEvents] = useState(new Set());
  const [currentAnswers, setCurrentAnswers] = useState({});

  useEffect(() => {
    if (!currentMemberId) {
      navigate("/member/login");
    } else {
      // 获取会员基本信息
      const fetchMemberInfo = async () => {
        try {
          const response = await axios.get(
            `https://australianwallstreet.com/api/member/${currentMemberId}`
          );
          setMemberInfo(response.data);
        } catch (error) {
          console.error("获取会员信息失败:", error);
        }
      };
      fetchMemberInfo();
    }
  }, [currentMemberId, navigate]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(
          `https://australianwallstreet.com/api/events?memberId=${currentMemberId}`
        );
        const activeEvents = response.data.filter((e) => e.status === "active");
        const sortedEvents = activeEvents.sort(
          (a, b) => new Date(a.time) - new Date(b.time)
        );

        // 修复初始化逻辑
        const joinedIds = new Set();
        sortedEvents.forEach((event) => {
          const isJoined = event.participants?.some(
            (p) => p.userId === currentMemberId
          );
          if (isJoined) joinedIds.add(event._id);
        });
        setJoinedEvents(joinedIds);

        setEvents(sortedEvents);
      } catch (error) {
        console.error("获取活动失败:", error);
      }
    };

    if (currentMemberId) {
      // 立即执行第一次获取
      fetchEvents();
      // 设置定时器每秒轮询
      const intervalId = setInterval(fetchEvents, 2000);
      // 组件卸载时清除定时器
      return () => clearInterval(intervalId);
    }
  }, [currentMemberId]);

  const handleJoinEvent = async (eventId) => {
    try {
      const targetEvent = events.find((e) => e._id === eventId);
      if (targetEvent.customQuestion && !currentAnswers[eventId]?.trim()) {
        return;
      }

      await axios.post(
        `https://australianwallstreet.com/api/events/${eventId}/join`,
        {
          memberId: currentMemberId,
          answer: currentAnswers[eventId] || "",
        }
      );

      // 清除该活动的答案
      setCurrentAnswers(prev => {
        const newAnswers = { ...prev };
        delete newAnswers[eventId];
        return newAnswers;
      });
      
      // 修复状态更新逻辑
      setEvents((prev) =>
        prev.map((event) =>
          event._id === eventId
            ? {
                ...event,
                participants: [
                  ...event.participants,
                  { userId: currentMemberId, answer: currentAnswers[eventId] || "" },
                ],
              }
            : event
        )
      );
      setJoinedEvents((prev) => new Set([...prev, eventId]));
    } catch (error) {
      console.error("加入活动失败:", error);
    }
  };

  const handleLeaveEvent = async (eventId) => {
    try {
      await axios.delete(
        `https://australianwallstreet.com/api/events/${eventId}/leave`,
        { data: { memberId: currentMemberId } }
      );

      // 修复状态更新逻辑
      setEvents((prev) =>
        prev.map((event) =>
          event._id === eventId
            ? {
                ...event,
                participants: event.participants.filter(
                  (p) => p.userId !== currentMemberId
                ),
              }
            : event
        )
      );
      setJoinedEvents((prev) => {
        const newSet = new Set(prev);
        newSet.delete(eventId);
        return newSet;
      });
    } catch (error) {
      console.error("退出活动失败:", error);
    }
  };

  const handleMarkAttendance = async (eventId) => {
    try {
      const response = await axios.post(
        `https://australianwallstreet.com/api/events/${eventId}/attend`,
        { memberId: currentMemberId }
      );

      // 更新本地状态
      setEvents((prev) =>
        prev.map((event) =>
          event._id === eventId
            ? {
                ...event,
                attendees: [...event.attendees, currentMemberId],
              }
            : event
        )
      );
    } catch (error) {
      console.error("标记签到失败:", error);
    }
  };

  return (
    <div className="dashboard-container">
      <style>{wukongStyles}</style>
      {memberInfo.fullName && (
        <div
          className="welcome-section"
          style={{ margin: "2rem", padding: "2rem" }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "1.5rem" }}>
            <div
              className="avatar"
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.5rem",
              }}
            >
              {memberInfo.fullName[0].toUpperCase()}
            </div>
            <div>
              <h1 style={{ fontSize: "2rem", margin: 0 }}>
                {memberInfo.fullName}
              </h1>
              <div
                className="status-badge"
                style={{
                  padding: "0.25rem 0.75rem",
                  borderRadius: "20px",
                  marginTop: "0.5rem",
                }}
              >
                {memberInfo.society} {words.memberStatus}
              </div>
            </div>
          </div>
        </div>
      )}

      <div style={{ margin: "2rem" }}>
        <h2 style={{ fontSize: "1.75rem", marginBottom: "1.5rem" }}>
          {words.currentEvents}
        </h2>

        <div
          className="event-grid"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
            gap: "1.5rem",
          }}
        >
          {events.map((event) => (
            <div
              className="event-card"
              key={event._id}
              style={{ padding: "1.5rem" }}
            >
              <div
                className="status-badge"
                style={{
                  position: "absolute",
                  top: "0.5rem",
                  right: "0.5rem",
                  padding: "0.25rem 0.75rem",
                }}
              >
                {event.signupStatus === "open" ? "开放报名" : "报名截止"}
              </div>

              <div style={{ marginBottom: "1rem" }}>
                <h3 style={{ fontSize: "1.25rem", marginBottom: "0.5rem" }}>
                  {event.title}
                </h3>
                <div style={{ color: "#999" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                      marginBottom: "0.5rem",
                    }}
                  >
                    <span>📅</span>
                    {new Date(event.time).toLocaleDateString()} ·{" "}
                    {new Date(event.time).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                    }}
                  >
                    <span>📍</span>
                    {event.place}
                  </div>
                </div>
              </div>

              {event.description && (
                <p style={{ color: "#999", marginBottom: "1rem" }}>
                  {event.description}
                </p>
              )}

              {joinedEvents.has(event._id) && event.imageUrl && (
                <div
                  style={{
                    marginBottom: "1rem",
                    borderRadius: "8px",
                    overflow: "hidden",
                    boxShadow: "0 2px 8px rgba(0,0,0,0.2)",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      color: "#fff",
                      fontSize: "0.9rem",
                      marginBottom: "0.5rem",
                    }}
                  >
                    保存图片并用微信扫描
                  </div>

                  <img
                    src={`https://australianwallstreet.com/api/events/qrcode/${event.imageUrl}`}
                    alt="活动群二维码还没好, 请过几天再试或联系 微信小助手"
                    style={{
                      width: "100%",
                      height: "auto",
                      maxHeight: "300px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              )}

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                {event.signupStatus === "open" && (
                  <>
                    {event.customQuestion && (
                      <textarea
                        className="textarea-custom"
                        value={
                          joinedEvents.has(event._id)
                            ? `${event.customQuestion}\n\n你的回答：${
                                event.participants?.find(
                                  (p) => p.userId === currentMemberId
                                )?.answer || ""
                              }`
                            : currentAnswers[event._id] || ""
                        }
                        onChange={(e) =>
                          !joinedEvents.has(event._id) &&
                          setCurrentAnswers(prev => ({
                            ...prev,
                            [event._id]: e.target.value
                          }))
                        }
                        placeholder={event.customQuestion}
                        disabled={joinedEvents.has(event._id)}
                        style={{
                          padding: "0.5rem",
                          borderRadius: "4px",
                          marginBottom: "0.5rem",
                          resize: "vertical",
                          minHeight: "80px",
                          maxHeight: "200px",
                        }}
                      />
                    )}

                    {joinedEvents.has(event._id) ? (
                      <button
                        className="button-primary"
                        disabled
                        style={{ 
                          padding: "0.8rem 1rem",
                          cursor: 'not-allowed',
                          opacity: 0.7,
                          lineHeight: 1.3,
                          textAlign: 'center'
                        }}
                      >
                        已报名 ✓
                        <br />
                        <span style={{ fontSize: '0.85rem', opacity: 0.9 }}>
                          (请用微信扫描二维码进群)
                        </span>
                      </button>
                    ) : (
                      <div style={{ position: "relative" }}>
                        <button
                          className="button-primary"
                          onClick={() => handleJoinEvent(event._id)}
                          disabled={!!event.customQuestion && !currentAnswers[event._id]?.trim()}
                          style={{
                            padding: "0.5rem 1rem",
                            width: "100%",
                          }}
                        >
                          {words.joinButton}
                        </button>
                        {!!event.customQuestion && !currentAnswers[event._id]?.trim() && (
                          <span className="disabled-tooltip">
                            ※ 请先回答上方问题
                          </span>
                        )}
                      </div>
                    )}
                  </>
                )}

                {event.attendanceStatus === "open" &&
                  joinedEvents.has(event._id) && (
                    <button
                      className="button-primary"
                      onClick={() => handleMarkAttendance(event._id)}
                      disabled={event.attendees?.includes(currentMemberId)}
                      style={{ padding: "0.5rem 1rem" }}
                    >
                      {event.attendees?.includes(currentMemberId)
                        ? "已签到 ✓"
                        : words.attendanceButton}
                    </button>
                  )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MemberDashboard;
