import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ChakraProvider } from "@chakra-ui/react";

import "./App.css";
import {
  LanguageProvider,
  ThemeProvider,
  useTheme,
} from "./components/index/ThemeContext"; // Assuming this is your theme context
import { motion, AnimatePresence } from "framer-motion";

// Import your components
import Navbar from "./components/index/Navbar";
import Cover from "./components/index/Cover";
import AboutUs from "./components/index/AboutUs";
import ComingEvents from "./components/index/ComingEvents";
import PastEvents from "./components/index/PastEvents";
import BlogSection from "./components/index/BlogSection";
import BecomeMember from "./components/index/BecomeMember";
import Contact from "./components/index/Contact";
import Wechat from "./components/wechat/wechat";
import AdminArticles from "./components/admin/AdminArticles";
import EntryPage from "./components/index/EntryPage";
import Login from "./components/memberPortal/Login";
import Signup from "./components/memberPortal/Signup";
import MemberDashboard from "./components/memberPortal/MemberDashboard";
import AdminEvents from "./components/admin/AdminEvents";
import ExecPage from "./components/admin/ExecPage";

// Reusable component to trigger theme change
const ThemedPage = ({ changeTheme }) => {
  const [rerenderKey, setRerenderKey] = useState(0); // State to track re-renders

  useEffect(() => {
    changeTheme(); // Trigger the theme change when the component mounts
    setRerenderKey((prevKey) => prevKey + 1);
  }, [changeTheme]);

  return (
    <motion.div
      initial={{ opacity: 0.1 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.8 }}
    >
      <header>
        <Navbar />
      </header>
      <Cover />
      <AboutUs />
      <ComingEvents />
      <PastEvents key={`past-events-${rerenderKey}`} />
      <BlogSection key={`blog-section-${rerenderKey}`} />
      <BecomeMember />
      <Contact />
    </motion.div>
  );
};

// Pro Page
const ProPage = () => {
  const { changeToPro } = useTheme();
  return <ThemedPage changeTheme={changeToPro} />;
};

// UNSW Page
const UNSWPage = () => {
  const { changeToUNSW } = useTheme();
  return <ThemedPage changeTheme={changeToUNSW} />;
};

// UMelb Page
const UMelbPage = () => {
  const { changeToUMelb } = useTheme();
  return <ThemedPage changeTheme={changeToUMelb} />;
};

function AnimatedRoutes() {
  const location = useLocation();

  return (
    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<EntryPage />} />
          {/* Theme-specific routes */}
          <Route path="/pro" element={<ProPage />} />
          <Route path="/unsw" element={<UNSWPage />} />
          <Route path="/umelb" element={<UMelbPage />} />
          <Route path="/wechat" element={<Wechat />} />
          <Route path="/admin/articles" element={<AdminArticles />} />
          <Route path="/admin/events" element={<AdminEvents />} />
          <Route path="/admin/exec" element={<ExecPage />} />
          <Route path="/member/login" element={<Login />} />
          <Route path="/member/signup" element={<Signup />} />
          <Route path="/member/dashboard" element={<MemberDashboard />} />
        </Routes>
      </AnimatePresence>

      {/* 页脚自动置于底部 */}
      {location.pathname !== "/" &&
        location.pathname !== "/pro" &&
        location.pathname !== "/unsw" &&
        location.pathname !== "/umelb" && (
          <div style={{ marginTop: "auto" }}>
            <Contact />
          </div>
        )}
    </div>
  );
}

function App() {
  return (
    <ChakraProvider resetCSS={false}>
      <Router>
        <div
          className="App"
          style={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <LanguageProvider>
            <ThemeProvider>
              <AnimatedRoutes />
            </ThemeProvider>
          </LanguageProvider>
        </div>
      </Router>
    </ChakraProvider>
  );
}

export default App;
