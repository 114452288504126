import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Input,
  Textarea,
  FormControl,
  FormLabel,
  VStack,
  HStack,
  Image,
  Text,
  useToast,
  Badge,
  Select,
  CheckboxGroup,
  Stack,
  Checkbox,
  Flex,
} from "@chakra-ui/react";
import axios from "axios";
import moment from "moment-timezone";

function AdminEvents() {
  const [events, setEvents] = useState([]);
  const [currentEvent, setCurrentEvent] = useState({
    id: null,
    title: "",
    time: "",
    place: "",
    description: "",
    image: null,
    imagePreview: "",
    customQuestion: "",
    selectedSocieties: [],
  });
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [adminPassword, setAdminPassword] = useState(
    localStorage.getItem("adminPassword") || ""
  );
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [storedPassword, setStoredPassword] = useState(
    localStorage.getItem("adminPassword") || ""
  );
  const [selectedEvent, setSelectedEvent] = useState(null);

  const baseUrl = "https://australianwallstreet.com/api/host/events";

  const axiosConfig = {
    params: {
      password: adminPassword,
    },
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentEvent((prev) => ({ ...prev, [name]: value }));
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCurrentEvent((prev) => ({
          ...prev,
          image: file,
          imagePreview: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append("hostingSoc", "AWS Pro");
    formData.append("title", currentEvent.title);
    const localDateTime = new Date(currentEvent.time);
    const timeString = moment(localDateTime)
      .tz("Australia/Sydney", true)
      .format("YYYY-MM-DDTHH:mm");
    formData.append("time", timeString);
    formData.append("place", currentEvent.place);
    formData.append("description", currentEvent.description);
    if (currentEvent.image) {
      formData.append("image", currentEvent.image);
    }
    formData.append("customQuestion", currentEvent.customQuestion);
    formData.append(
      "selectedSocieties",
      JSON.stringify(currentEvent.selectedSocieties || [])
    );

    try {
      const url = currentEvent.id ? `${baseUrl}/${currentEvent.id}` : baseUrl;

      const method = currentEvent.id ? "put" : "post";

      const response = await axios[method](url, formData, {
        params: {
          password: storedPassword,
        },
      });

      setEvents((prev) =>
        currentEvent.id
          ? prev.map((event) =>
              event._id === currentEvent.id ? response.data : event
            )
          : [...prev, response.data]
      );
      setCurrentEvent({
        id: null,
        title: "",
        time: "",
        place: "",
        description: "",
        image: null,
        imagePreview: "",
        customQuestion: "",
        selectedSocieties: [],
      });
      toast({
        title: "成功",
        description: "新活动已添加",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "错误",
        description: "保存活动失败",
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = async (event) => {
    try {
      const response = await axios.get(baseUrl, {
        params: { password: localStorage.getItem("adminPassword") },
      });

      const eventData = response.data.find((e) => e._id === event._id);

      setCurrentEvent({
        id: eventData._id,
        title: eventData.title,
        time: moment(eventData.time).format("YYYY-MM-DDTHH:mm"),
        place: eventData.place,
        description: eventData.description,
        imageUrl: eventData.imageUrl,
        imagePreview: eventData.imageUrl
          ? `https://australianwallstreet.com/api/events/qrcode/${eventData.imageUrl}`
          : "",
        customQuestion: eventData.customQuestion,
        selectedSocieties: eventData.selectedSocieties,
      });

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } catch (error) {
      toast({
        title: "错误",
        description: "获取活动详情失败",
        status: "error",
      });
    }
  };

  const handleDelete = async (id) => {
    const isConfirm = window.confirm("确定要停用此活动吗？该操作不可逆！");
    if (!isConfirm) return;

    try {
      await axios.patch(`${baseUrl}/${id}/status`, {
        status: "inactive",
      });
      setEvents((prev) => prev.filter((event) => event._id !== id));
      toast({
        title: "已停用",
        description: "活动已标记为停用",
        status: "info",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "错误",
        description: "操作失败",
        status: "error",
      });
    }
  };

  const handleToggleSignupStatus = async (id, currentStatus) => {
    try {
      const newStatus = currentStatus === "open" ? "closed" : "open";
      await axios.patch(`${baseUrl}/${id}/signup-status`, {
        signupStatus: newStatus,
      });
      setEvents((prev) =>
        prev.map((event) =>
          event._id === id ? { ...event, signupStatus: newStatus } : event
        )
      );
    } catch (error) {
      toast({
        title: "错误",
        description: "更新报名状态失败",
        status: "error",
      });
    }
  };

  const handleAttendanceStatusChange = async (eventId, newStatus) => {
    try {
      await axios.patch(`${baseUrl}/${eventId}/attendance-status`, {
        status: newStatus,
      });
      setEvents((prev) =>
        prev.map((event) =>
          event._id === eventId
            ? { ...event, attendanceStatus: newStatus }
            : event
        )
      );
    } catch (error) {
      toast({
        title: "错误",
        description: "更新考勤状态失败",
        status: "error",
      });
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    localStorage.setItem("adminPassword", adminPassword);
    setStoredPassword(adminPassword);
    try {
      setIsAuthenticated(true);
      await fetchEvents();
    } catch (error) {
      // 错误处理
    }
  };

  const fetchEvents = useCallback(async () => {
    try {
      const response = await axios.get(baseUrl, {
        params: { password: localStorage.getItem("adminPassword") },
      });
      setEvents(response.data);
    } catch (error) {
      console.error("获取活动失败:", error);
    }
  }, [baseUrl]);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  useEffect(() => {
    const interceptor = axios.interceptors.request.use((config) => {
      if (isAuthenticated) {
        const savedPassword = localStorage.getItem("adminPassword");
        if (savedPassword) {
          if (config.method === "get") {
            config.params = {
              ...config.params,
              password: savedPassword,
            };
          } else {
            config.data = config.data || {};
            if (config.data instanceof FormData) {
              config.data.append("password", savedPassword);
            } else {
              config.data = { ...config.data, password: savedPassword };
            }
          }
        }
      }
      return config;
    });

    return () => axios.interceptors.request.eject(interceptor);
  }, [isAuthenticated]);

  useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 403) {
          localStorage.removeItem("adminPassword");
          setIsAuthenticated(false);
          toast({
            title: "密码错误",
            description: "请重新输入管理员密码",
            status: "error",
          });
        }
        return Promise.reject(error);
      }
    );

    return () => axios.interceptors.response.eject(responseInterceptor);
  }, []);

  useEffect(() => {
    if (selectedEvent) {
      const updatedEvent = events.find((e) => e._id === selectedEvent._id);
      if (updatedEvent) {
        setSelectedEvent(updatedEvent);
      } else {
        setSelectedEvent(null);
      }
    }
  }, [events, selectedEvent]);

  return (
    <Box p={5} maxW="1200px" mx="auto">
      {!isAuthenticated ? (
        <Box maxW="400px" mx="auto" mt={20}>
          <form onSubmit={handlePasswordSubmit}>
            <FormControl isRequired>
              <FormLabel>管理员密码</FormLabel>
              <Input
                type="password"
                value={adminPassword}
                onChange={(e) => setAdminPassword(e.target.value)}
                placeholder="请输入管理员密码"
                p={0}
              />
            </FormControl>
            <Button type="submit" colorScheme="blue" width="full" mt={4}>
              验证密码
            </Button>
          </form>
        </Box>
      ) : (
        <VStack spacing={6} align="stretch">
          {/* 表单部分 */}
          <Box
            as="form"
            onSubmit={handleSubmit}
            p={{ base: 3, md: 5 }}
            boxShadow="md"
            borderRadius="md"
            width="100%"
            maxWidth={{ base: "100%", md: "800px" }}
            mx="auto"
          >
            <Text fontSize="2xl" mb={4}>
              {currentEvent.id ? "编辑活动" : "创建新活动"}
            </Text>

            <FormControl isRequired mb={4}>
              <FormLabel>活动标题</FormLabel>
              <Input
                name="title"
                value={currentEvent.title}
                onChange={handleInputChange}
                placeholder="请输入活动标题"
                width="100%"
                p={0}
              />
            </FormControl>

            <FormControl isRequired mb={4}>
              <FormLabel>时间</FormLabel>
              <Input
                type="datetime-local"
                name="time"
                value={currentEvent.time}
                onChange={handleInputChange}
                width="100%"
                p={0}
              />
            </FormControl>

            <FormControl isRequired mb={4}>
              <FormLabel>地点</FormLabel>
              <Input
                name="place"
                value={currentEvent.place}
                onChange={handleInputChange}
                placeholder="请输入活动地点"
                width="100%"
                p={0}
              />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>活动描述</FormLabel>
              <Textarea
                name="description"
                value={currentEvent.description}
                onChange={handleInputChange}
                placeholder="请输入活动详情描述"
                rows={4}
                p={0}
                resize="vertical"
              />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>上传活动群二维码</FormLabel>
              <Input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                p={0}
                py={1}
              />
              {currentEvent.imagePreview && (
                <Image
                  src={currentEvent.imagePreview}
                  alt="封面预览"
                  mt={2}
                  maxW="200px"
                  borderRadius="md"
                />
              )}
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>自定义报名问题</FormLabel>
              <Input
                name="customQuestion"
                value={currentEvent.customQuestion}
                onChange={handleInputChange}
                placeholder="例如：请说明参加本次活动的目的"
                p={0}
              />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>可参与活动的社团</FormLabel>
              <CheckboxGroup
                value={currentEvent.selectedSocieties || []}
                onChange={(values) =>
                  setCurrentEvent((prev) => ({
                    ...prev,
                    selectedSocieties: values,
                  }))
                }
                colorScheme="blue"
              >
                <Stack direction={["column", "row"]} spacing={4}>
                  {["AWS Pro", "AWS UNSW", "AWS UMelb"].map((society) => (
                    <Checkbox key={society} value={society}>
                      {society}
                    </Checkbox>
                  ))}
                </Stack>
              </CheckboxGroup>
            </FormControl>

            <Button
              type="submit"
              colorScheme="blue"
              width="100%"
              isLoading={isLoading}
              mt={4}
            >
              {currentEvent.id ? "更新活动" : "创建活动"}
            </Button>
          </Box>

          {/* 活动列表 */}
          <Box mt={8}>
            <Text fontSize="2xl" mb={4}>
              已创建的活动
            </Text>

            <FormControl mb={4}>
              <Select
                placeholder="选择要查看的活动"
                onChange={(e) => {
                  const selected = events.find(
                    (event) => event._id === e.target.value
                  );
                  setSelectedEvent(selected || null);
                }}
                width="100%"
                maxWidth="400px"
              >
                {events.map((event) => (
                  <option key={event._id} value={event._id}>
                    {event.title} - {new Date(event.time).toLocaleDateString()}
                  </option>
                ))}
              </Select>
            </FormControl>

            {selectedEvent && (
              <Box
                p={{ base: 3, md: 4 }}
                borderWidth="1px"
                borderRadius="md"
                boxShadow="sm"
                width="100%"
                maxWidth="100%"
                overflow="hidden"
                mt={4}
              >
                <Flex
                  justify="space-between"
                  direction={{ base: "column", md: "row" }}
                  gap={2}
                  mb={2}
                >
                  <Flex align="center" flexWrap="wrap">
                    <Text fontSize={{ base: "lg", md: "xl" }} fontWeight="bold">
                      {selectedEvent.title}
                    </Text>
                    <Badge
                      ml={2}
                      colorScheme={
                        selectedEvent.status === "active" ? "green" : "red"
                      }
                      fontSize={{ base: "xs", md: "sm" }}
                    >
                      {selectedEvent.status === "active" ? "激活" : "停用"}
                    </Badge>
                  </Flex>

                  <Stack direction={{ base: "column", md: "row" }} spacing={2}>
                    <Button
                      size="sm"
                      onClick={() => handleEdit(selectedEvent)}
                      width={{ base: "100%", md: "auto" }}
                    >
                      编辑
                    </Button>
                    <Button
                      size="sm"
                      colorScheme={
                        selectedEvent.status === "active" ? "red" : "green"
                      }
                      onClick={() => handleDelete(selectedEvent._id)}
                      width={{ base: "100%", md: "auto" }}
                    >
                      {selectedEvent.status === "active" ? "停用" : "激活"}
                    </Button>
                  </Stack>
                </Flex>
                {selectedEvent.selectedSocieties && (
                  <HStack mt={2} spacing={2}>
                    {selectedEvent.selectedSocieties.map((society) => (
                      <Badge key={society} colorScheme="blue" variant="subtle">
                        {society}
                      </Badge>
                    ))}
                  </HStack>
                )}
                {selectedEvent.imageUrl && (
                  <Image
                    src={`https://australianwallstreet.com/api/events/qrcode/${selectedEvent.imageUrl}`}
                    alt="活动群二维码"
                    mt={2}
                    maxW={{ base: "100%", md: "150px" }}
                    objectFit="contain"
                  />
                )}

                <Text>
                  时间: {new Date(selectedEvent.time).toLocaleString()}
                </Text>
                <Text>地点: {selectedEvent.place}</Text>
                {selectedEvent.description && (
                  <Text mt={2} color="gray.600">
                    {selectedEvent.description}
                  </Text>
                )}
                <Box mt={4}>
                  <Flex
                    direction={{ base: "column", md: "row" }}
                    gap={4}
                    wrap="wrap"
                  >
                    <Box flex={1} minWidth="200px">
                      <Text fontWeight="medium" mb={2}>
                        报名状态：
                        <Badge
                          ml={2}
                          colorScheme={
                            selectedEvent.signupStatus === "open"
                              ? "green"
                              : "red"
                          }
                          fontSize="md"
                          cursor="pointer"
                          onClick={() =>
                            handleToggleSignupStatus(
                              selectedEvent._id,
                              selectedEvent.signupStatus
                            )
                          }
                        >
                          {selectedEvent.signupStatus === "open"
                            ? "开放中"
                            : "已关闭"}
                        </Badge>
                      </Text>
                      <Button
                        size="sm"
                        colorScheme={
                          selectedEvent.signupStatus === "open"
                            ? "red"
                            : "green"
                        }
                        onClick={() =>
                          handleToggleSignupStatus(
                            selectedEvent._id,
                            selectedEvent.signupStatus
                          )
                        }
                        width="full"
                      >
                        {selectedEvent.signupStatus === "open"
                          ? "立即关闭报名"
                          : "重新开放报名"}
                      </Button>
                    </Box>

                    <Box flex={1} minWidth="200px">
                      <Text fontWeight="medium" mb={2}>
                        考勤状态：
                        <Badge
                          ml={2}
                          colorScheme={
                            selectedEvent.attendanceStatus === "open"
                              ? "green"
                              : "red"
                          }
                          fontSize="md"
                          cursor="pointer"
                          onClick={() =>
                            handleAttendanceStatusChange(
                              selectedEvent._id,
                              selectedEvent.attendanceStatus === "open"
                                ? "closed"
                                : "open"
                            )
                          }
                        >
                          {selectedEvent.attendanceStatus === "open"
                            ? "开放中"
                            : "已关闭"}
                        </Badge>
                      </Text>
                      <Button
                        size="sm"
                        colorScheme={
                          selectedEvent.attendanceStatus === "open"
                            ? "red"
                            : "green"
                        }
                        onClick={() =>
                          handleAttendanceStatusChange(
                            selectedEvent._id,
                            selectedEvent.attendanceStatus === "open"
                              ? "closed"
                              : "open"
                          )
                        }
                        width="full"
                      >
                        {selectedEvent.attendanceStatus === "open"
                          ? "立即关闭考勤"
                          : "重新开放考勤"}
                      </Button>
                    </Box>

                    <Box flex={1} minWidth="200px">
                      <Text fontWeight="medium">参与数据：</Text>
                      <VStack align="start" mt={2} spacing={1}>
                        <Text fontSize="sm">
                          📝 已报名: {selectedEvent.participants?.length || 0}{" "}
                          人
                        </Text>
                        <Text fontSize="sm">
                          ✅ 已签到: {selectedEvent.attendees?.length || 0} 人
                        </Text>
                      </VStack>
                    </Box>
                  </Flex>
                </Box>
                {selectedEvent.customQuestion && (
                  <Box
                    mt={2}
                    p={2}
                    bg="gray.100"
                    borderRadius="md"
                    width="100%"
                    maxWidth="100%"
                    overflow="hidden"
                  >
                    <Text fontWeight="semibold" textAlign="left">
                      自定义问题:
                    </Text>
                    <Text
                      whiteSpace="pre-wrap"
                      wordBreak="break-word"
                      textAlign="left"
                    >
                      {selectedEvent.customQuestion}
                    </Text>
                    {selectedEvent.participants?.length > 0 && (
                      <Box mt={2} textAlign="left">
                        <Text>已收集回答:</Text>
                        {selectedEvent.participants.map((p, idx) => (
                          <Text
                            key={idx}
                            fontSize="sm"
                            color="gray.600"
                            whiteSpace="pre-wrap"
                            wordBreak="break-word"
                          >
                            {idx + 1}. {p.answer || "无回答"}
                          </Text>
                        ))}
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            )}

            {events.length === 0 && (
              <Text color="gray.500" mt={4}>
                暂无活动
              </Text>
            )}
          </Box>
        </VStack>
      )}
    </Box>
  );
}

export default AdminEvents;
