import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";

import "swiper/css";
import "swiper/css/bundle";

// import styles from './styles/ComingEvents.module.css';
import styles from "./styles/ComingEvents.module.css";
import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

import wordsJson from "./words.json";
import eventImg from "./img/ComingEvents/eventImg.json";

import { useTheme, useLanguage } from "./ThemeContext";
import ProTheme from "./styles/PRO/ComingEvents.module.css";
import UNSWTheme from "./styles/UNSW/ComingEvents.module.css";
import MQUTheme from "./styles/MQU/ComingEvents.module.css";
import UMelbTheme from "./styles/UMelb/ComingEvents.module.css";

function ComingEvents() {
  const { language } = useLanguage();
  const headingContent = wordsJson.admin[language].comingEventSection;

  const { theme } = useTheme();

  const wordsData = wordsJson[theme][language].comingEvent;
  



  const [themeStyles, setThemeStyles] = useState(ProTheme);
  const [showBox, setShowBox] = useState(false);

  useEffect(() => {
    switch (theme) {
      case "UNSW":
        setThemeStyles(UNSWTheme);
        break;
      case "UMelb":
        setThemeStyles(UMelbTheme);
        break;
      case "MQU":
        setThemeStyles(MQUTheme);
        break;
      default:
        setThemeStyles(ProTheme);
    }
  }, [theme]);

  useEffect(() => {
    const handleScroll = () => {
      const { offsetTop, offsetHeight } =
        document.getElementById("ComingEvents");
      if (window.scrollY > offsetTop - offsetHeight / 1.5 && !showBox) {
        setShowBox(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showBox]);

  const [isPhone, setIsPhone] = useState(window.innerWidth < 1600);
  useEffect(() => {
    const handleResize = () => {
      setIsPhone(window.innerWidth < 1600);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isPhone]);

  return (
    <section
      id="ComingEvents"
      className={`${themeStyles.background} ${styles.background}`}
    >
      <div className={`${themeStyles.section_title} ${styles.section_title}`}>
        {headingContent.title}
      </div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: showBox ? 1 : 0 }}
        transition={{ duration: 2 }}
        className={`${themeStyles.box} ${styles.box}`}
      >
        {!isPhone && (
          <div className={`${themeStyles.calander} ${styles.calander}`}>
            <div className={`${themeStyles.month} ${styles.month}`}>
              {wordsData.comingEventMonth}
            </div>
            <div className={styles.date}>{wordsData.comingEventDate}</div>
          </div>
        )}

        <div className={`${themeStyles.eventContent} ${styles.eventContent}`}>
          <div className={styles.eventTitle}>{wordsData.comingEventTitle}</div>
          <div className={styles.eventTimeLocationContainer}>
            <div className={styles.eventTime}>
              <FontAwesomeIcon icon={far.faClock} />
              <span> </span>
              {isPhone && <>{wordsData.comingEventDate}</>}
              <span> </span>
              {isPhone && <>{wordsData.comingEventMonth}</>}
              <span> </span>
              {wordsData.comingEventTime}
              <span> </span>
            </div>
            <div className={styles.eventLocation}>
              <FontAwesomeIcon icon={fas.faLocationDot} />
              <span> </span>
              {wordsData.comingEventLocation}
            </div>
          </div>
          <div className={styles.eventDescription}>
            {wordsData.comingEventContent.map((paragraph, index) => (
              <p key={index} className={`${styles.text} ${themeStyles.text}`}>
                {paragraph}
              </p>
            ))}
          </div>
        </div>

        <div className={`${themeStyles.eventJoin} ${styles.eventJoin}`}>
          <img
            className={`${themeStyles.eventImg} ${styles.eventImg}`}
            src={require(`./img/ComingEvents/${eventImg[theme]}`)}
            alt="Event1"
          />
          <a
            href={wordsData.signUpURL}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.signUpButton}
          >
            {headingContent.signUpButton}
          </a>
        </div>
      </motion.div>
    </section>
  );
}

export default ComingEvents;
