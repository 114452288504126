import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useLanguage } from "../index/ThemeContext";
import axios from "axios";
import memberWords from "./words.json";

// 在组件前添加CSS样式
const styles = `
.login-container {
  max-width: 500px;
  margin: 10rem auto;
  padding: 3rem;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 30px rgba(0,0,0,0.12);
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.login-container::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(45deg, transparent, rgba(0,0,0,0.1) 50%);
  transform: rotate(45deg);
  z-index: -1;
}

h2 {
  color: #1a1a1a;
  text-align: center;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  position: relative;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
}

form {
  position: relative;
  z-index: 1;
}

form > div {
  margin-bottom: 2rem;
  padding: 0 0.5rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: #4d4d4d;
  font-weight: 500;
  font-size: 1.1rem;
}

input {
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  border: 2px solid #e6e6e6;
  border-radius: 8px;
  font-size: 1.1rem;
  height: 52px;
  transition: all 0.3s ease;
}

input:focus {
  outline: none;
  border-color: #ff4d4f;
  box-shadow: 0 0 0 3px rgba(255,77,79,0.1);
}

button {
  width: calc(100% - 1rem);
  margin: 0 0.5rem;
  padding: 12px;
  background: #1a1a1a;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
}

button:hover {
  background: #ff4d4f;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(255,77,79,0.3);
}

p.error {
  color: #ff4d4f;
  background: #fff2f0;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ffccc7;
  margin: 1rem 0;
  text-align: center;
}

p:last-child {
  text-align: center;
  margin-top: 1.5rem;
  color: #666;
  position: relative;
  z-index: 2;
}

p a {
  color: #ff4d4f;
  text-decoration: none;
  font-weight: 500;
}

@media (max-width: 480px) {
  .login-container {
    margin: 5rem auto;
    padding: 2rem;
  }

  h2 {
    font-size: 1.75rem;
  }

  input {
    padding: 14px;
    font-size: 1rem;
    height: 48px;
  }

  button {
    padding: 14px;
    font-size: 1rem;
  }
}
`;

function Login() {
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { language } = useLanguage();
  const words = memberWords[language].login;

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://australianwallstreet.com/api/member/login",
        { phone }
      );
      localStorage.setItem("currentMemberId", response.data.id);
      console.log("登录成功，会员ID:", response.data.id);
      navigate("/member/dashboard");
    } catch (err) {
      if (err.response) {
        setError("登录失败, 如果您确认您已经注册, 请联系微信小助手(aws__unsw) 或 Email awsunsw.tech@gmail.com");
      } else {
        setError("无法连接服务器");
      }
    }
  };

  return (
    <div className="login-container">
      <style>{styles}</style>
      <h2>{words.title}</h2>
      <form onSubmit={handleLogin}>
        <div style={{ marginBottom: "2rem" }}>
          <label style={{ fontSize: "1.1rem" }}>{words.phoneLabel}</label>
          <input
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
        </div>
        {error && <p className="error">{error}</p>}
        <button type="submit">{words.submitButton}</button>
      </form>
      <p>
        {words.noAccount}
        <Link to="/member/signup">{words.signupLink}</Link>
      </p>
    </div>
  );
}

export default Login;
