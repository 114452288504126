import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import React, { useRef, useState, useEffect } from "react";
import { useTheme, useLanguage } from "./ThemeContext";
import styles from "./styles/PastEvents.module.css";
import "swiper/css";
import "./styles/Swiper.css";
import ProTheme from "./styles/PRO/PastEvents.module.css";
import UNSWTheme from "./styles/UNSW/PastEvents.module.css";
import MQUTheme from "./styles/MQU/PastEvents.module.css";
import UMelbTheme from "./styles/UMelb/PastEvents.module.css";
import cover from "./img/PastEventCover/pastEventCover.json";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";

import wordsJson from "./words.json";
import axios from "axios";
import { motion } from "framer-motion";

function PastEvents() {
  const { language } = useLanguage();
  const headingContent = wordsJson.admin[language].pastEventSection;
  const { theme } = useTheme();

  const wordsData = wordsJson[theme][language].pastEvent;
  const [themeStyles, setThemeStyles] = useState(ProTheme);

  useEffect(() => {
    switch (theme) {
      case "UNSW":
        setThemeStyles(UNSWTheme);
        break;
      case "UMelb":
        setThemeStyles(UMelbTheme);
        break;
      case "MQU":
        setThemeStyles(MQUTheme);
        break;
      default:
        setThemeStyles(ProTheme);
    }
  }, [theme]);

  const [events, setEvents] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);

  const fetchBlogs = (url, filters = {}) => {
    axios
      .get(url, { params: filters })
      .then((response) => {
        setEvents(
          response.data.map((blog, index) => ({ ...blog, Id: index + 1 }))
        );
      })
      .catch((error) => {
        console.error("Failed to fetch blogs:", error);
        setEvents([]); // Set an empty array in case of an error
      });
  };

  useEffect(() => {
    // Function to fetch blogs based on URL
    let url = "https://australianwallstreet.com/api/event/pro";
    switch (theme) {
      case "Pro":
        url = "https://australianwallstreet.com/api/event/pro";
        break;
      case "UNSW":
        url = "https://australianwallstreet.com/api/event/unsw";
        break;
      case "UMelb":
        url = "https://australianwallstreet.com/api/event/melb";
        break;
      default:
        url = "https://australianwallstreet.com/api/event/pro";
    }
    const filters = {};
    if (selectedTypes.length > 0) {
      filters.categories = selectedTypes.join(",");
    }


    fetchBlogs(url, filters);
  }, [theme, selectedTypes]);

  const [loadedEventsIndexStart, setLoadedEventsIndexStart] = useState(0);
  const [loadedEventsIndexEnd, setLoadedEventsIndexEnd] = useState(2);
  const [loadedEventsIndexCurr, setLoadedEventsIndexCurr] = useState(0);
  const displayedEvents = events.slice(
    Math.max(loadedEventsIndexStart, 0),
    Math.min(loadedEventsIndexEnd, events.length)
  );
  /////////////////// Function for page navigation ///////////////////////
  // Functions for navigation, only 3 slide at a time, always on middle slide
  var systemSkip = false;
  const swiperRef = useRef(null);
  const goToSlide = (number, speed = 0.9) => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(number, speed);
    }
  };

  const skipToEvents = (id) => {
    if (loadedEventsIndexCurr === 0) {
      systemSkip = true;
    }

    if (id === 1) {
      setLoadedEventsIndexStart(0);
      setLoadedEventsIndexEnd(2);
      setLoadedEventsIndexCurr(0);
      systemSkip = true;
      goToSlide(0);
      return;
    }
    forceRerender();

    setLoadedEventsIndexStart(id - 2);
    setLoadedEventsIndexCurr(id - 1);
    setLoadedEventsIndexEnd(id + 1);
    setTimeout(() => {
      goToSlide(1);
    }, 10);
  };

  const handleNextTransitionStart = (swiper) => {
    if (events.length === 0) {
      return;
    }

    if (systemSkip) {
      systemSkip = false;
      return;
    }

    if (loadedEventsIndexCurr === 0) {
      setLoadedEventsIndexCurr(loadedEventsIndexCurr + 1);
      setLoadedEventsIndexEnd(loadedEventsIndexEnd + 1);
      return;
    }

    if (loadedEventsIndexCurr === events.length - 1) {
      return;
    }
    setLoadedEventsIndexStart(loadedEventsIndexStart + 1);
    setLoadedEventsIndexCurr(loadedEventsIndexCurr + 1);
    setLoadedEventsIndexEnd(loadedEventsIndexEnd + 1);
    goToSlide(1);
  };

  const handlePrevTransitionStart = (swiper) => {
    // Do nothing if it came from 2nd page
    if (loadedEventsIndexStart === 0) {
      return;
    }
    // Do nothing if come from skipToEvents to 1st page
    if (systemSkip) {
      systemSkip = false;
      return;
    }
    setLoadedEventsIndexStart(loadedEventsIndexStart - 1);
    setLoadedEventsIndexCurr(loadedEventsIndexCurr - 1);
    setLoadedEventsIndexEnd(loadedEventsIndexEnd - 1);
    setTimeout(() => goToSlide(1), 10);
  };

  //////////////// Scroll Reset Function ///////////////////////////
  const scrollRefs = useRef([]);

  const resetScrollPosition = (index) => {
    if (scrollRefs.current[index]) {
      scrollRefs.current[index].scrollTop = 0;
    }
  };

  const resetScrollPositionAll = () => {
    resetScrollPosition(0);
    resetScrollPosition(1);
    resetScrollPosition(2);
  };

  //////////////// Brief View Function ///////////////////////////

  const [showOverview, setShowOverview] = useState(false);
  const [showOverviewOnce, setShowOverviewOnce] = useState(false);
  const handleOverview = () => {
    setShowOverview(!showOverview);
  };

  // Show overview when first scrolled to the section
  useEffect(() => {
    const handleScroll = () => {
      const { offsetTop, offsetHeight } = document.getElementById("PastEvents");
      if (window.scrollY > offsetTop - offsetHeight / 4 && !showOverviewOnce) {
        setShowOverviewOnce(true);
        setShowOverview(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showOverviewOnce]);

  //////////////// Search Bar Function ///////////////////////////
  const [isExpanded, setIsExpanded] = useState(true); // State to toggle expanded/collapsed search bar
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = (query) => {
    setSearchQuery(query);
    if (query.length > 0) {
      const results = events
        .map((event) => ({
          ...event,
          title: language === "English" ? event.titleEN : event.titleCN,
        }))
        .filter((event) =>
          event.title.toLowerCase().includes(query.toLowerCase())
        )
        .sort((a, b) => a.title.length - b.title.length) // Simple similarity measure
        .slice(0, 5);
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  };
  //////////////// FILTER ///////////////////////////
  const [showFilter, setShowFilter] = useState(false);
  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedTypes((prevSelectedTypes) => [...prevSelectedTypes, value]);
    } else {
      setSelectedTypes((prevSelectedTypes) =>
        prevSelectedTypes.filter((type) => type !== value)
      );
    }
    // Go to first page
    setLoadedEventsIndexStart(0);
    setLoadedEventsIndexEnd(2);
    setLoadedEventsIndexCurr(0);
  };

  //////////// Force Render Upon Theme Change ///////////////////////
  const [key, setKey] = useState(0);
  const forceRerender = () => setKey((prevKey) => prevKey + 1);
  useEffect(() => {
    forceRerender();
    setLoadedEventsIndexStart(0);
    setLoadedEventsIndexEnd(2);
    setLoadedEventsIndexCurr(0);
    setSearchQuery("");
    setSelectedTypes([]);
    setSearchResults([]);
    systemSkip = false;
  }, [theme]);

  return (
    <section id="PastEvents" className={styles.box}>
      <div className={styles.contentBox}>
        {/* Section Description */}
        <div className={styles.event_brief_container}>
          <h2 className={`${styles.sectionTitle} ${themeStyles.sectionTitle}`}>
            {headingContent.title}
          </h2>
          <div className={styles.event_brief_description_container}>
            <p
              className={`${styles.sectionDescription} ${themeStyles.sectionDescription}`}
            >
              {wordsData.description}
            </p>
          </div>

          <div className={styles.brief_img_container}>
            <img
              className={styles.brief_img}
              src={require(`./img/PastEventCover/${cover[theme]}`)}
              alt="Past Events Brief"
            />
          </div>
        </div>

        <div className={styles.event_articles_container}>
          <div className={styles.searchItemSystemContainer}>
            {/* Overview Dropdown */}
            <div className={styles.overviewDropdown}>
              <button
                className={`${styles.overviewButton} ${themeStyles.overviewButton}`}
                onClick={handleOverview}
              >
                {!showOverview
                  ? headingContent.viewButton
                  : headingContent.closeButton}
              </button>
              <motion.div
                initial={{ height: 0, width: 0 }}
                animate={{
                  height: showOverview ? 415 : 0,
                  width: showOverview ? 300 : 0,
                  visibility: showOverview ? "visible" : "hidden",
                }}
                transition={{ duration: 0.5 }}
                exit={{ height: 0, width: 0 }}
                className={`${styles.overviewForm} ${themeStyles.overviewForm}`}
              >
                <div
                  className={`${styles.overviewContainer} ${themeStyles.overviewContainer}`}
                >
                  {events.map((event, idx) => (
                    <button
                      className={`${styles.eventNavButton} ${themeStyles.eventNavButton}`}
                      key={idx}
                      onClick={() => {
                        skipToEvents(event.Id);
                        setShowOverview(false);
                      }}
                    >
                      {language === "English" ? event.titleEN : event.titleCN}
                    </button>
                  ))}
                </div>
              </motion.div>
            </div>

            {/* Search Bar */}
            <div className={styles.pageNavContainer}>
              <div
                className={`${styles.searchBox} ${
                  isExpanded ? styles.expanded : styles.collapsed
                } ${themeStyles.searchBox}`}
              >
                <div
                  className={`${styles.searchOutline} ${themeStyles.searchOutline}`}
                  onClick={() => setIsExpanded(true)}
                >
                  <FontAwesomeIcon icon={fas.faMagnifyingGlass} />
                </div>
                {isExpanded && (
                  <>
                    <div
                      className={`${styles.searchInput} ${themeStyles.searchInput}`}
                    >
                      <input
                        type="text"
                        placeholder="Search within filter"
                        value={searchQuery}
                        onChange={(e) => handleSearch(e.target.value)}
                      />
                    </div>
                    <div
                      className={`${styles.close} ${themeStyles.close}`}
                      onClick={() => setIsExpanded(false)}
                    >
                      <FontAwesomeIcon icon={fas.faXmark} />
                    </div>
                    {searchResults.length > 0 && (
                      <div
                        className={`${styles.searchResults} ${themeStyles.searchResults}`}
                      >
                        {searchResults.map((result, idx) => (
                          <div
                            key={idx}
                            className={`${styles.searchResult} ${themeStyles.searchResult}`}
                            onClick={() => {
                              setIsExpanded(false);
                              skipToEvents(result.Id);
                            }}
                          >
                            <div className={styles.searchMagGlass}>
                              {" "}
                              <FontAwesomeIcon
                                icon={fas.faMagnifyingGlass}
                              />{" "}
                            </div>
                            {result.title}
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>

            {/* Filter Button */}
            <div
              className={`${styles.filterDropdown} ${themeStyles.filterDropdown}`}
            >
              <button
                className={`${styles.filterButton} ${themeStyles.filterButton}`}
                onClick={handleShowFilter}
              >
                {!showFilter
                  ? headingContent.filterButton
                  : headingContent.closeButton}
              </button>
              <motion.div
                initial={{ height: 0 }}
                animate={{
                  height: showFilter ? "auto" : 0,
                  visibility: showFilter ? "visible" : "hidden",
                }}
                transition={{ duration: 0.5 }}
                exit={{ height: 0 }}
                className={`${styles.filterForm} ${themeStyles.filterForm}`}
              >
                <fieldset>
                  <legend>Type</legend>
                  {wordsData.filter.map((category, index) => (
                    <div key={index}>
                      <label>
                        <input
                          type="checkbox"
                          value={category.value}
                          onChange={handleCheckboxChange}
                        />
                        <div className={styles.filterDisplay}>
                          {category.display}
                        </div>
                      </label>
                    </div>
                  ))}
                </fieldset>
              </motion.div>
            </div>
          </div>

          {/* Swiper for Past Events */}
          <Swiper
            className={`${styles.swiper} ${themeStyles.swiper}`}
            key={key}
            modules={[Navigation, Pagination]}
            navigation={true}
            onSwiper={(swiper) => (swiperRef.current = swiper)}
            breakpoints={{
              769: { slidesPerView: 1, slidesPerGroup: 1 },
            }}
            onReachEnd={(swiper) => {
              setTimeout(() => {
                // Go back to middle slide and reset scroll position after 4 seconds
                resetScrollPositionAll();
                handleNextTransitionStart(swiper);
              }, 400);
            }}
            onReachBeginning={(swiper) => {
              setTimeout(() => {
                resetScrollPositionAll();
                handlePrevTransitionStart(swiper);
              }, 400);
            }}
          >
            {displayedEvents.map((event, index) => (
              <SwiperSlide key={index}>
                <div
                  className={`${styles.pastEventTitle} ${themeStyles.pastEventTitle}`}
                >
                  {language === "English" ? event.titleEN : event.titleCN}
                </div>
                <div
                  ref={(el) => (scrollRefs.current[index] = el)}
                  className={`${styles.scrollableContainer} ${themeStyles.scrollableContainer}`}
                >
                  <img
                    className={styles.pastEventImage}
                    src={`https://australianwallstreet.com/api/articles/${
                      language === "English" ? event.urlEN : event.urlCN
                    }`}
                    alt="Pasts Event"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
}

export default PastEvents;
