import React, { useState, useEffect } from "react";
import {
  Box,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Input,
  Button,
  VStack,
  useToast,
  Flex,
  Spacer,
} from "@chakra-ui/react";
import axios from "axios";

const ExecPage = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [participants, setParticipants] = useState([]);
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const toast = useToast();

  const fetchEvents = async () => {
    try {
      const response = await axios.get(
        "https://australianwallstreet.com/api/executives/events",
        { params: { password } }
      );
      setEvents(response.data);
    } catch (error) {
      toast({
        title: "获取活动失败",
        description: error.message,
        status: "error",
      });
    }
  };

  const fetchParticipants = async (eventId) => {
    try {
      const response = await axios.get(
        `https://australianwallstreet.com/api/executives/${eventId}/participants`,
        { params: { password } }
      );
      setParticipants(response.data);
    } catch (error) {
      toast({
        title: "获取数据失败",
        description: "请检查密码或网络连接",
        status: "error",
      });
    }
  };

  const handlePasswordSubmit = async () => {
    try {
      // 发送密码到后端验证
      await axios.get(
        "https://australianwallstreet.com/api/executives/events",
        { params: { password } }
      );
      setIsAuthenticated(true);
      fetchEvents();
    } catch (error) {
      toast({
        title: "验证失败",
        description: "密码错误或连接问题",
        status: "error",
      });
    }
  };

  if (!isAuthenticated) {
    return (
      <Box p={5} maxW="400px" mx="auto">
        <VStack spacing={4}>
          <Input
            type="password"
            placeholder="输入管理员密码"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button onClick={handlePasswordSubmit} colorScheme="blue" w="full">
            验证密码
          </Button>
        </VStack>
      </Box>
    );
  }

  return (
    <Box p={{ base: 3, md: 5 }} maxW={{ base: "100%", md: "1200px" }} mx="auto">
      <Flex mb={4} direction={{ base: "column", md: "row" }}>
        <Spacer display={{ base: "none", md: "block" }} />
        <Button
          mt={{ base: 2, md: 0 }}
          width={{ base: "100%", md: "auto" }}
          colorScheme="green"
          onClick={async () => {
            const presidentPassword = prompt("请输入主席密码");
            if (!presidentPassword) return;

            try {
              const response = await axios.get(
                "https://australianwallstreet.com/api/executives/download-unsw-members",
                {
                  params: {
                    password,
                    presidentPassword,
                  },
                  responseType: "blob",
                }
              );

              // 创建下载链接
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "unsw-members.csv");
              document.body.appendChild(link);
              link.click();
              link.remove();
            } catch (error) {
              toast({
                title: "下载失败",
                description: "密码错误或网络问题",
                status: "error",
              });
            }
          }}
        >
          下载UNSW会员数据
        </Button>
      </Flex>
      <VStack spacing={4} align="stretch">
        <Select
          width="100%"
          placeholder="选择活动"
          value={selectedEvent}
          onChange={async (e) => {
            setSelectedEvent(e.target.value);
            await fetchParticipants(e.target.value);
          }}
        >
          {events.map((event) => (
            <option key={event._id} value={event._id}>
              {event.title} - {new Date(event.time).toLocaleDateString()}
            </option>
          ))}
        </Select>

        {participants.length > 0 ? (
          <Box overflowX="auto">
            <Table variant="simple" minWidth="600px">
              <Thead>
                <Tr>
                  <Th minWidth="120px">姓名</Th>
                  <Th minWidth="100px">学号</Th>
                  <Th minWidth="120px">微信号</Th>
                  <Th minWidth="200px">邮箱</Th>
                </Tr>
              </Thead>
              <Tbody>
                {participants.map((participant, index) => (
                  <Tr key={index}>
                    <Td>{participant.fullName}</Td>
                    <Td>{participant.uniID}</Td>
                    <Td>{participant.wechatID}</Td>
                    <Td>{participant.email}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        ) : (
          <Text textAlign="center" mt={4}>
            {selectedEvent ? "暂无参与者" : "请先选择一个活动"}
          </Text>
        )}
      </VStack>
    </Box>
  );
};

export default ExecPage;
